import React, { InputHTMLAttributes, forwardRef } from 'react';

import * as Styled from './styles';

/**
 * Code Input
 */

const inputStyle = {
  fontFamily: 'Inter',
  margin: '4px',
  width: '25px',
  fontSize: '20px',
  outline: 'none',
  'text-align': 'center',
  height: '26px',
  padding: '6px',
  color: 'black',
  border: '2px solid #2FAF92',
};

export interface CodeInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    Styled.StyledCodeInputProps {
  code: string;
  setCode: (value: string) => void;
  customStyles?: any;
  withBorder?: boolean;
}

const CodeInput = forwardRef<HTMLInputElement, CodeInputProps>(
  ({ code, setCode, customStyles, withBorder }: CodeInputProps, ref: any) => {
    return (
      <Styled.StyledReactCodeInput
        ref={ref}
        name="codeInput"
        inputMode="numeric"
        fields={6}
        value={code}
        onChange={(value: string) => setCode(value)}
        inputStyle={customStyles || inputStyle}
        withBorder={withBorder}
      />
    );
  },
);

export default CodeInput;
