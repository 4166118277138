import { Margin, marginModifier } from '@app/utils/modifiers';
import styled, { css } from 'styled-components';
import FlexBox from '../FlexBox';

export type StyledCheckboxProps = {
  name?: string;
  checked?: boolean;
  size?: 'lg' | 'md';
  uncheckedBg?: string;
  checkedBg?: string;
  uncheckedBorder?: string;
  checkedBorder?: string;
  disabled?: boolean;
  disabledBorderColor?: string;
};

const sizeModifiers = {
  lg: css`
    height: 22px;
    width: 22px;
  `,
  md: css`
    height: 20px;
    width: 20px;
  `,
};

export const Container = styled(FlexBox)<{
  width?: string;
  label?: string;
  margin?: Margin;
  noBorder?: boolean;
  disabled?: boolean;
  disabledOpacity?: boolean;
}>`
  ${({ margin }) => marginModifier(margin)};
  border: ${({ label, noBorder }) =>
    label && !noBorder ? '1px solid #D9D9D9' : '0'};
  border-radius: 5px;
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  cursor: pointer;

  ${({ disabled, disabledOpacity }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: ${disabledOpacity ? 0.5 : 1};
    `};
`;

export const CheckBoxDiv = styled(FlexBox)<StyledCheckboxProps>`
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;

  background: ${({ checked, checkedBg, uncheckedBg }) =>
    checked
      ? checkedBg
        ? checkedBg
        : '#E0B09C'
      : uncheckedBg || 'transparent'};
  border: ${({ checked, checkedBorder, uncheckedBorder, theme }) =>
    `2px solid ${
      checked && checkedBorder
        ? checkedBorder
        : uncheckedBorder || theme.colors.secondary
    }`};
  &:focus {
    outline: 2px solid #cf673e;
  }

  ${({ disabled, disabledBorderColor }) =>
    disabled &&
    css`
      border: 2px solid ${disabledBorderColor || '#cf673e'};
    `};

  ${({ size = 'lg' }) => sizeModifiers[size]};
`;

export const Input = styled.input`
  display: none;
`;
